import React from "react"

import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css'; // Import whole style

import styled from '@emotion/styled'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Button, Card, Steps, Space } from 'antd';
import { Typography, Divider } from 'antd';
import { CodeOutlined, CopyOutlined, RiseOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { Tabs } from 'antd';

import { getCode } from '../lib/helpers';

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const Step = Steps.Step;

export const Block = styled.div`
    margin: 1rem 0;
`;

const PrivacyPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Getting Started"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <Title>Documentation</Title>
                    <h2>Getting Started</h2>
                </div>
                <section>
                    <Title id="install">MyCashflow - Theme</Title>
                    <Paragraph>
                        Reko returns 4 most similar products.
                    </Paragraph>
                    <Paragraph>
                        To display recommendations, place the JavaScript widget and HTML tag in MyCashflow theme.
                    </Paragraph>
                    <Block>
                    <Tabs defaultActiveKey="1" onChange={console.log}>
                        <TabPane tab="JS Widget" key="1">
                            <div>
                                <Title level={3}><CodeOutlined /> JavaScript Widget</Title>
                                <Paragraph>Change the STORE_ID to your STORE_ID. Copy and paste this JavaScript widget to bottom of <code>Product</code> page tempalte. Place the HTML tag to where you want to display the recommendations box in.</Paragraph>
                                {/* <Form.Item label="storeId">
                                    <Input value="storeId" />
                                </Form.Item> */}
                                <Card title="Widget">
                                    <pre>
                                        {true && getCode('<STORE_ID>')}
                                    </pre>
                                </Card>
                            </div>
                        </TabPane>
                        <TabPane tab="HTML Tag" key="2">
                            <Title level={3}>HTML Tag</Title>
                            <Paragraph>To display recommendations box, place this tag on <code>Product</code> page.</Paragraph>
                            <Paragraph><code>h2</code> title can be changed. It will be hidden if no results are found.</Paragraph>
                            <Card title="Display Recommendations">
                                <pre>
                                    {`<div id="reko-similar-wrapper"><h2>Samankaltaiset</h2><div id="reko-similar"></div></div>`}
                                </pre>
                            </Card>
                        </TabPane>
                        <TabPane tab="HTML Output" key="3">
                            <Title level={3}>HTML Output</Title>
                            <Paragraph>The JS widget calls MyCashflow Interface API <code>/interface/Products</code> with default parameters. It returns default product HTML for the recommendations. </Paragraph>
                            <Card title="Default HTML">
                                <pre>
                                {`<div class="ProductList reko-widget-recommendations reko-widget-similar">
            <div class="Product Available">
                <a href="/product/15834/akg-p5i-perception-live-dynaaminen-laulumikrofoni" class="ProductImage" title="AKG P5i Perception Live dynaaminen laulumikrofoni">
                    <img src="/tuotekuvat/180x180/AKGP5i.png" alt="AKG P5i Perception Live dynaaminen laulumikrofoni" />
                </a>
                <h2 class="ProductName">
                    <a href="/product/15834/akg-p5i-perception-live-dynaaminen-laulumikrofoni">AKG P5i Perception Live dynaaminen laulumikrofoni</a>
                </h2>
                <dl class="Prices">
                    <dt class="Price">Hinta</dt>
                    <dd class="Price">78,90&nbsp;€</dd>
                </dl>
                <a href="/product/15834/akg-p5i-perception-live-dynaaminen-laulumikrofoni" title="Lisää ostoskoriin" class="Button AddToCart"><span>Lisää ostoskoriin</span></a>
            </div>
        </div>`}
                                </pre>
                            </Card>
                        </TabPane>
                    </Tabs>
                    </Block>
                    <Divider style={{margin: '80px 0'}}></Divider>
                    <Block>
                        <Title id="onboarding">Mycashflow - Onboarding</Title>
                        <Paragraph>
                            Register a new MyCashflow store to Reko.
                        </Paragraph>
                        <Title level={2}>Request</Title>
                        <Paragraph>
                            <code>feedUrl</code> is the URL for the product feed. <code>storeId</code> is a unique identifier for store version.
                        </Paragraph>
                        <Paragraph>
                            <code> POST https://t46vsyosjh.execute-api.eu-north-1.amazonaws.com/prod/register </code>
                        </Paragraph>
                        <Paragraph>
                            <code> {`{"feedUrl": 'https://<store-domain.mycashflow.fi/feed/feed.xml', "storeId": "<storeId>"}`} </code>
                        </Paragraph>
                        <Paragraph>
                            <code>Header 'x-api-key': {`<API_KEY>`}</code>
                        </Paragraph>
                        <Paragraph>
                            <Title level={2}>Response</Title>
                            Returns HTTP 201 if successful. 400 with error message if something went wrong.
                            {/* {"feedUrl": 'https://<store-domain.mycashflow.fi/feed/feed.xml', "storeId": "<storeId>"} */}
                        </Paragraph>
                    </Block>
                    <Divider style={{margin: '80px 0'}}></Divider>
                    <Block>
                        <Title id="api">API</Title>
                        <Title level={2}>Similar</Title>
                        <Paragraph>
                            Query recommendations for a single product by calling the API at <code>https://fo3cjvxiib.execute-api.eu-north-1.amazonaws.com/dev/similar?id=STORE_ID.PRODUCT_ID</code>.
                        </Paragraph>
                        <Paragraph>
                            <code>GET /similar?id=STORE_ID.PRODUCT_ID</code>
                        </Paragraph>
                        <Paragraph>
                            <Title level={4}>Response</Title>
                            Ordered JSON Array of recommendations for the queried product.
                        </Paragraph>
                        <Paragraph>
                        <code>
                            [
                                "5",
                                "234",
                                "20-482",
                                "20-481",
                                "20-782",
                                "297-589",
                                "297-590",
                                "297-591",
                                "222-809",
                                "222-810"
                            ]
                        </code>
                        </Paragraph>
                    </Block>
                </section>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage
