export function getCode(storeId) {
    const code = `
    <!-- Reko Widget -->
    <script>
        (function (w,d,s,o,f,js,fjs) {
            w['Reko-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', 'reko', 'https://cdn.reko.ai/reko.js'));
        var rekoMCFProduct = {Helper(file: '{{ { id: '{ProductID}' } }}', case: 'IsProduct', or: 'false' )};
        reko('init', { storeId: ${storeId}, rekoMCFData: { product: rekoMCFProduct }} );
    </script>
    `;
    return code;
}